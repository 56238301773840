import { useEffect, useState } from "react"
import React from "react";
import Navbar from "./Navbar"
import { ActivityService, SolidarityChallengeService } from "../services/Services";
import ProgressBar from "./ProgressBar";
import Modal from 'react-modal';
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Select from "react-select";
import { UseAlertMessageLogin } from './Alert';
import { Pagination } from "@mui/material";
import DOMPurify from 'dompurify'
import { PhotoPopup } from "./PhotoPopup";
import { RWebShare } from "react-web-share";
import Markdown from 'markdown-to-jsx'

export default function Homepage2(props) {
    const [loading, setLoading] = useState(true)
    const [filter_loading, setFilterLoading] = useState(false)
    const [leaderboard_loading, setLeaderboardLoading] = useState(true)
    const [joined, setJoined] = useState(false)
    const [global_recently_joined, setGlobalRecentlyJoined] = useState([])
    const [global, setGlobal] = useState(true)
    const [balance_data, setBalance] = useState(true)
    const [is_privacy_policy, setIsPrivacyPolicy] = useState(sessionStorage.getItem('privacy_policy') === "true" ? true : false)
    const [conversion_info, setConversionInfo] = useState('')
    const [infoPopup, setInfoPopup] = useState('')
    const [everyone, setEveryone] = useState(false)
    const [today, setToday] = useState(false)
    const [week, setWeek] = useState(true)
    const [total, setTotal] = useState(false)
    const [team_name, setTeamName] = useState(null)
    const [today_region, setTodayRegion] = useState(true)
    const [week_region, setWeekRegion] = useState(false)
    const [today_team, setTodayTeam] = useState(false)
    const [week_team, setWeekTeam] = useState(true)
    const [total_team, setTotalTeam] = useState(false)
    const [month_region, setMonthRegion] = useState(false)
    const [region_loading, setRegionLoading] = useState(false)
    const [team_loading, setTeamLoading] = useState(false)
    const [regions, setRegions] = useState([])
    const [seeAllTeamModalStatus, setSeeAllTeamModalStatus] = useState(false)
    const [myTeamModalStatus, setMyTeamModalStatus] = useState(false)
    const [createTeamStatus, setCreateTeamModalStatus] = useState(false)
    const [seeAllLeaderboardModalStatus, setSeeAllLeaderboardModalStatus] = useState(false)
    const [month, setMonth] = useState(false)
    const [publishPopup, setPublishPopup] = useState(false)
    const [meals, setMeals] = useState('')
    const [user, setUser] = useState(null)
    const [type, setType] = useState('user')
    const [challenge_id, setChallengeId] = useState('')
    const [leaderboard, setLeaderboard] = useState([])
    const [modal_leaderboard, setModalLeaderboard] = useState([])
    const [activities, setActivities] = useState([])
    const [selectedActivity, setSelectedActivity] = useState([])
    const [selectedActivityLabel, setSelectedActivityLabel] = useState([])
    const [measurements, setMeasurements] = useState([])
    const [measurementValue, setMeasurementValue] = useState('')
    const [measurementSelectLabel, setMeasurementSelectLabel] = useState([])
    const [measurementsLabel, setMeasurementLabel] = useState([])
    const [measurementSelectValue, setMeasurementSelectValue] = useState([])
    const [my_team, setMyTeam] = useState([])
    const [my_team_info, setMyTeamInfo] = useState('')
    const [team_info, setTeamInfo] = useState('')
    const [modal_my_team, setModalMyTeam] = useState([])
    const [selected_modal_team, setSelectedModalTeam] = useState([])
    const [selected_total_modal_team, setTotalModalTeam] = useState([])
    const [filtered_selected_team_users, setFilteredSelectedTeamUsers] = useState([])
    const [modal_team, setModalTeam] = useState([])
    const [total_my_team, setTotalMyTeam] = useState([])
    const [total_leaderboard, setTotalLeaderboard] = useState([])
    const [total_filtered_my_team, setTotalFilteredMyTeam] = useState([])
    const [total_filtered_selected_team, setTotalFilteredSelectedTeam] = useState([])
    const [total_filtered_team, setTotalFilteredTeam] = useState([])
    const [filtered_my_team, setFilteredMyTeam] = useState([])
    const [filtered_teams, setFilteredTeams] = useState([])
    const [teamLeaderboard, setTeamLeaderboard] = useState([])
    const [region_leaderboard, setRegionLeaderboard] = useState([])
    const [filtered_leaderboard, setFilteredLeaderboard] = useState([])
    const [total_filtered_leaderboard, setTotalFilteredLeaderboard] = useState([])
    const [my_position, setMyPosition] = useState('')
    const [challenge, setChallenge] = useState('')
    const [page_items, setPageItems] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [search_text, setSearch_text] = useState('')
    const [activity_date, setActivityDate] = useState(new Date())
    const [activity_loader, setActivityLoader] = useState(false)
    const [tracking_loader, setTrackingLoader] = useState(false)
    const [activityModalStatus, setActivityModalStatus] = useState(false)
    const [teamModalStatus, setTeamModalStatus] = useState(false)
    const [my_trackings, setTracking] = useState(false)
    const [trackingModalStatus, setTrackingModalStatus] = useState(false)
    const [recently_joined_loading, setRecentlyJoinedLoading] = useState(false)
    const [recently_joined, setRecentlyJoined] = useState([])
    const [selectedTracking, setSelectedTracking] = useState('')
    const [modal_delete_tracking, setModalDeleteTracking] = useState(false)
    const [team_created, setTeamCreated] = useState(false)
    const [modalTeamInfoPopuo, setModalTeamInfoPopuo] = useState(false)
    const [privacy_policy, setPrivacyPolicy] = useState(sessionStorage.getItem('privacy_policy') === "true" ? true : false)
    let sc = new SolidarityChallengeService();
    let as = new ActivityService();

    useEffect(() => {
        if (joined) {
            if (props.user && props.user.modules && props.user.modules.gsc && props.user.modules.gsc.challenges && props.user.modules.gsc.challenges.length > 0) {
                sc.getMeals(props.user.modules.gsc.challenges[0].id, setMeals)
            }
        }
        setLeaderboardLoading(true)
        let filters = {}
        if (!props.user.modules.gsc.challenges[0].is_active)
            filters = {
                "is_global": global,
                "time": ''
            }
        else
            filters = {
                "is_global": global,
                "time": today ? 'day' : (week ? 'week' : '')
            }

        let filters_team = {}
        if (!props.user.modules.gsc.challenges[0].is_active)
            filters_team = {
                "time": ''
            }
        else
            filters_team = {
                "time": today_team ? 'day' : (week_team ? 'week' : '')
            }

        /**/

        let path = window.location.href.split('staging/')
        let original_path = path[0] + 'staging/'
        if (window.location.hostname === 'changemakerschallenge.gucci.cn') {
            path = window.location.href.split('.cn/')
            original_path = path[0] + '.cn/'
        } else if (window.location.hostname == 'changemakerschallenge.gucci.com') {
            path = window.location.href.split('.com/')
            original_path = path[0] + '.com/'
        }
        if (path.length > 1 && path[1] === '?privacy_policy') {
            setPrivacyPolicy(true)
            window.history.pushState('homepage', 'homepage', original_path)
        }

        sessionStorage.setItem('privacy_policy', false)


        if (props.user && props.user.modules && props.user.modules.gsc && props.user.modules.gsc.challenges && props.user.modules.gsc.challenges.length > 0) {
            let challengeId = props.user.modules.gsc.challenges[0].id
            if (window.location.href.includes('team/')) {
                path = window.location.href.split('team/')
                setFilterLoading(true)
                setTeamModalStatus(true)
                sc.getTeamById(challengeId, path[1].split('/')[0]).then((data) => {
                    setTeamInfo(data)
                    setSelectedModalTeam(data.members)
                    setTotalModalTeam(data.members)
                    setFilterLoading(false)
                    window.history.pushState('homepage', 'homepage', window.location.href.split('team/')[0])
                })
            } else if (sessionStorage.getItem('team_id')) {
                setFilterLoading(true)
                setTeamModalStatus(true)
                sc.getTeamById(challengeId, sessionStorage.getItem('team_id').split('/')[0]).then((data) => {
                    setTeamInfo(data)
                    setSelectedModalTeam(data.members)
                    setTotalModalTeam(data.members)
                    setFilterLoading(false)
                    sessionStorage.removeItem('team_id')
                    window.history.pushState('homepage', 'homepage', window.location.href.split('team/')[0])
                })
            }
            setChallengeId(challengeId)


            //get with UserFilters
            getLeaderboard(challengeId, filters)

            sc.getTeamLeaderboard(challengeId, filters_team).then((data) => {
                setTeamLeaderboard(data)
                setModalTeam(data)
            }).finally(() => {
                setTeamLoading(false)
            })

            sc.getMyPosition(challengeId, filters).then((data) => {
                setMyPosition(data)
            })
            sc.getChallengeInfo(challengeId).then((data) => {
                setChallenge(data)
            })
            sc.getRecentlyJoined(challengeId, !everyone).then((data) => {
                setRecentlyJoinedFunction(data)
            }).finally(() => {
                setRecentlyJoinedLoading(false)
            })
            sc.getBalance(challengeId).then((data) => {
                setBalance(data)
            }).finally(() => {
                setLoading(false)
            })

            as.getActivities(challengeId).then((data) => {
                setActivitiesFunction(data)
            })
            sc.getConversionInfo(challengeId).then((data) => {
                setConversionInfo(data)
            })
        }

        setUserFunction(props.user)

        setTimeout(() => {

            if (props.scroll && window.screen.width < 600) {
                let coin = document.getElementById('coin')
                let value = coin.offsetTop - 100
                let a = document.getElementsByClassName('boxes_container_animation')
                a[0].scrollTo({ left: 0, top: value, behavior: 'smooth' })
            }
            setLoading(false)
        }, 2000);

        if (props.user && props.user.modules && props.user.modules.gsc && !props.user.modules.gsc.challenges[0].is_active) {
            setToday(false)
            setWeek(false)
            setMonth(true)
        }


    }, [])
    useEffect(() => {
        if (challenge_id !== '') {

            let filters = {}
            if (!props.user.modules.gsc.challenges[0].is_active)
                filters = {
                    "is_global": global,
                    "time": ''
                }
            else

                filters = {
                    "is_global": global,
                    "time": today ? 'day' : (week ? 'week' : '')
                }

            getLeaderboard(challenge_id, filters)
        }
    }, [type])

    const getLeaderboard = (challengeId, filters) => {

        if (type === 'region')
            sc.getChallengeRegionLeaderboard(challengeId, filters).then((data) => {
                setRegionLeaderboard(data)
            }).finally(() => {
                setLeaderboardLoading(false)
            })
        else
            sc.getChallengeLeaderboard(challengeId, filters).then((data) => {
                setLeaderboardFunction(data)
            }).finally(() => {
                setLeaderboardLoading(false)
            })
    }
    /*
    const connectStrava = (id) => {
        setLoading(true)
        sc.connectToStrava(id).finally(() => { setLoading(false) })
    }*/

    const handleChangePage = (e, newPage) => {
        setFilterLoading(true)

        setTimeout(() => {


            let val = page_items * (newPage - 1)

            let t = []
            if (search_text === '') {
                t = leaderboard.slice(val, page_items * newPage)
                setModalLeaderboard(t)
            }
            else {
                t = total_filtered_leaderboard.slice(val, page_items * newPage)
                setFilteredLeaderboard(t)
            }
            setCurrentPage(newPage)
            setFilterLoading(false)
        }, 500);
    }

    const handleChangePageTeam = (e, newPage) => {
        setFilterLoading(true)

        setTimeout(() => {

            let val = page_items * (newPage - 1)
            let t = []
            if (search_text === '') {
                t = teamLeaderboard.slice(val, page_items * newPage)
                setModalTeam(t)
            }
            else {
                t = filtered_teams.slice(val, page_items * newPage)
                setFilteredTeams(t)
            }
            setFilterLoading(false)

            setCurrentPage(newPage)
        }, 500);
    }
    const handleChangePageSingleTeam = (e, newPage) => {
        setFilterLoading(true)

        setTimeout(() => {

            let val = page_items * (newPage - 1)
            let t = []
            if (search_text === '') {
                t = selected_total_modal_team.slice(val, page_items * newPage)
                setSelectedModalTeam(t)
            }
            else {
                t = filtered_selected_team_users.slice(val, page_items * newPage)
                setFilteredSelectedTeamUsers(t)
            }
            setFilterLoading(false)

            setCurrentPage(newPage)
        }, 500);
    }
    const handleChangePageMyTeam = (e, newPage) => {
        setFilterLoading(true)

        setTimeout(() => {


            let val = page_items * (newPage - 1)
            let t = []
            if (search_text === '') {
                t = my_team.slice(val, page_items * newPage)
                setModalMyTeam(t)
            }
            else {
                t = filtered_my_team.slice(val, page_items * newPage)
                setFilteredMyTeam(t)
            }

            setFilterLoading(false)
            setCurrentPage(newPage)
        }, 500);
    }

    const handleText = (e) => {
        setFilterLoading(true)
        setTimeout(() => {
            let tmp = []
            let tmp_leaderboard = []

            tmp = (total_leaderboard.filter(r => r.user.last_name.toLowerCase().toLowerCase().includes(e.target.value.toLowerCase()) ||
                (r.user.first_name.toLowerCase() + ' ' + r.user.last_name.toLowerCase()).includes(e.target.value.toLowerCase()) ||
                r.user.first_name.toLowerCase().includes(e.target.value.toLowerCase()) || r.user.location.toLowerCase().includes(e.target.value.toLowerCase())
            ))
            tmp_leaderboard = tmp.slice(0, page_items)
            setSearch_text(e.target.value)
            setCurrentPage(1)
            setFilteredLeaderboard(tmp_leaderboard)
            setTotalFilteredLeaderboard(tmp)
            setFilterLoading(false)
        }, 1000);


    }
    const handleTextTeam = (e) => {
        setFilterLoading(true)
        setTimeout(() => {
            let tmp = []
            let tmp_my_team = []

            tmp = (teamLeaderboard.filter(r => r.team_name.toLowerCase().toLowerCase().includes(e.target.value.toLowerCase())))
            tmp_my_team = tmp.slice(0, page_items)
            setSearch_text(e.target.value)
            setCurrentPage(1)
            setFilteredTeams(tmp_my_team)
            setTotalFilteredMyTeam(tmp)
            setFilterLoading(false)
        }, 1000);


    }
    const handleTextSingleTeam = (e) => {
        setFilterLoading(true)
        setTimeout(() => {
            let tmp = []
            let tmp_my_team = []
            tmp = (selected_total_modal_team.filter(r => r.last_name.toLowerCase().toLowerCase().includes(e.target.value.toLowerCase()) ||
                (r.first_name.toLowerCase() + ' ' + r.last_name.toLowerCase()).includes(e.target.value.toLowerCase()) ||
                r.first_name.toLowerCase().includes(e.target.value.toLowerCase())))
            tmp_my_team = tmp.slice(0, page_items)
            setSearch_text(e.target.value)
            setCurrentPage(1)
            setFilteredSelectedTeamUsers(tmp_my_team)
            setTotalFilteredSelectedTeam(tmp)
            setFilterLoading(false)
        }, 1000);


    }
    const handleTextMyTeam = (e) => {
        setFilterLoading(true)
        setTimeout(() => {
            let tmp = []
            let tmp_my_team = []

            tmp = (my_team.filter(r => r.last_name.toLowerCase().toLowerCase().includes(e.target.value.toLowerCase()) ||
                (r.first_name.toLowerCase() + ' ' + r.last_name.toLowerCase()).includes(e.target.value.toLowerCase()) ||
                r.first_name.toLowerCase().includes(e.target.value.toLowerCase()) || r.location.toLowerCase().includes(e.target.value.toLowerCase())))
            tmp_my_team = tmp.slice(0, page_items)
            setSearch_text(e.target.value)
            setCurrentPage(1)
            setFilteredMyTeam(tmp_my_team)
            setTotalFilteredMyTeam(tmp)
            setFilterLoading(false)
        }, 1000);


    }

    const setUserFunction = (user) => {
        if (user.modules && user.modules.gsc && user.modules.gsc.challenges)
            user.modules.gsc.challenges.map((c) => {
                if (c.joined)
                    setJoined(true)
            })
        setUser(user)
    }
    const setActivitiesFunction = (activities) => {
        setSelectedActivity(activities[0].id)
        setSelectedActivityLabel(activities[0].name)
        setActivities(activities)
        setMeasurements(activities[0].unit)
        setMeasurementSelectValue(activities[0].unit[0].id)
        setMeasurementSelectLabel(activities[0].unit[0].label)

    }
    const setLeaderboardFunction = (leaderboard) => {
        setLeaderboard(leaderboard)
        setTotalLeaderboard(leaderboard)
        setFilteredLeaderboard(leaderboard)
        setModalLeaderboard(leaderboard)
    }
    /*
        const handleSwitch = () => {
            let g = !global

            let filters = {
                "is_global": g,
                "time": today == true ? 'day' : (week == true ? 'week' : '')
            }
            if (joined) {
                setGlobal(g)
                setLeaderboardLoading(true)
                sc.getMyPosition(challenge_id, filters).then((data) => {
                    setMyPosition(data)
                })
                sc.getChallengeLeaderboard(challenge_id, filters).then((data) => {
                    setLeaderboard(data)
                }).finally(() => {
                    setLeaderboardLoading(false)
                })
            }
            else
                setGlobal(g)
        }*/

    const handleButton = (button_name) => {
        switch (button_name) {
            case 'today':
                if (!today) {
                    setToday(!today)
                    setWeek(false)
                    setTotal(false)
                    setLeaderboardLoading(true)
                    let filters = {
                        "is_global": global,
                        "time": "day"
                    }
                    sc.getMyPosition(challenge_id, filters).then((data) => {
                        setMyPosition(data)
                    })
                    getLeaderboard(challenge_id, filters)
                }
                break;
            case 'week':
                if (!week) {
                    setWeek(!week)
                    setToday(false)
                    setTotal(false)

                    setLeaderboardLoading(true)
                    let filters = {
                        "is_global": global,
                        "time": "week"
                    }
                    sc.getMyPosition(challenge_id, filters).then((data) => {
                        setMyPosition(data)
                    })
                    getLeaderboard(challenge_id, filters)
                }

                break;
            case 'total':
                if (!total) {
                    setTotal(!total)
                    setWeek(false)
                    setToday(false)

                    setLeaderboardLoading(true)
                    let filters = {
                        "is_global": global,
                        "time": ""
                    }
                    sc.getMyPosition(challenge_id, filters).then((data) => {
                        setMyPosition(data)
                    })
                    getLeaderboard(challenge_id, filters)
                }

                break;
            case 'month':
                if (!month) {
                    setMonth(!month)
                    setWeek(false)
                    setToday(false)
                    setLeaderboardLoading(true)
                    let filters = {
                        "is_global": global,
                        "time": ""
                    }
                    sc.getMyPosition(challenge_id, filters).then((data) => {
                        setMyPosition(data)
                    })
                    sc.getChallengeLeaderboard(challenge_id, filters).then((data) => {
                        setLeaderboard(data)
                    }).finally(() => {
                        setLeaderboardLoading(false)
                    })
                }
                break;
            case 'everyone':
                if (!everyone) {
                    setEveryone(true)
                    setRecentlyJoinedLoading(true)
                    reloadRecentlyJoined(false)
                }
                break;
            case 'your_team':
                if (everyone) {
                    setEveryone(false)
                    setRecentlyJoinedLoading(true)
                    reloadRecentlyJoined(true)
                }
                break;
            default:
                break
        }

    }
    const handleRegionButton = (button_name) => {
        switch (button_name) {
            case 'today':
                if (!today_region) {
                    setTodayRegion(!today_region)
                    setWeekRegion(false)
                    setMonthRegion(false)
                    setRegionLoading(true)

                    let filters = {
                        "time": "day"
                    }
                    sc.getTeamLeaderboard(challenge_id, filters).then((data) => {
                        setTeamLeaderboard(data)
                        setModalTeam(data)
                        setRegionLoading(false)
                    })

                }
                break;
            case 'week':
                if (!week_region) {
                    setWeekRegion(!week_region)
                    setTodayRegion(false)
                    setMonthRegion(false)
                    setRegionLoading(true)

                    let filters = {
                        "time": "week"
                    }
                    sc.getChallengeRegionLeaderboard(challenge_id, filters).then((data) => {
                        setRegionLeaderboard(data)
                    }).finally(() => {
                        setRegionLoading(false)
                    })
                }
                break;
            case 'month':
                if (!month_region) {
                    setMonthRegion(!month_region)
                    setWeekRegion(false)
                    setTodayRegion(false)
                    setRegionLoading(true)

                    let filters = {
                        "time": ""
                    }

                    sc.getChallengeRegionLeaderboard(challenge_id, filters).then((data) => {
                        setRegionLeaderboard(data)
                    }).finally(() => {
                        setRegionLoading(false)
                    })
                }
                break;
            default:
                break
        }

    }
    const handleTeamButton = (button_name) => {
        switch (button_name) {
            case 'today':
                if (!today_team) {
                    setTodayTeam(!today_team)
                    setWeekTeam(false)
                    setTotalTeam(false)
                    setTeamLoading(true)

                    let filters = {
                        "time": "day"
                    }
                    sc.getTeamLeaderboard(challenge_id, filters).then((data) => {
                        setTeamLeaderboard(data)
                        setModalTeam(data)
                        setTeamLoading(false)
                    })

                }
                break;
            case 'week':
                if (!week_team) {
                    setWeekTeam(!week_team)
                    setTodayTeam(false)
                    setTotalTeam(false)
                    setTeamLoading(true)

                    let filters = {
                        "time": "week"
                    }
                    sc.getTeamLeaderboard(challenge_id, filters).then((data) => {
                        setTeamLeaderboard(data)
                        setModalTeam(data)
                    }).finally(() => {
                        setTeamLoading(false)
                    })
                }
                break;
            case 'month':
                if (!total_team) {
                    setTotalTeam(!total_team)
                    setWeekTeam(false)
                    setTodayTeam(false)
                    setTeamLoading(true)

                    let filters = {
                        "time": ""
                    }

                    sc.getTeamLeaderboard(challenge_id, filters).then((data) => {
                        setTeamLeaderboard(data)
                        setModalTeam(data)
                    }).finally(() => {
                        setTeamLoading(false)
                    })
                }
                break;
            default:
                break
        }

    }

    const handleModal = (val, modal, activity) => {
        switch (modal) {
            //join_challenge
            case 'join':
                if (val == true) {
                    setLeaderboardLoading(true)

                    if (!joined)
                        setJoined(true)

                    let filters = {
                        "is_global": global,
                        "time": (today ? 'day' : (week ? 'week' : ''))
                    }

                    sc.getMyPosition(challenge_id, filters).then((data) => {
                        setMyPosition(data)
                    })
                    sc.joinChallenge(challenge_id).then((data) => {
                        getChallengeLeaderboard(filters)
                    }).finally(() => {
                        setLeaderboardLoading(false)
                    })
                }
                break;
            case 'join_team':
                if (!val) {
                    setFilterLoading(false)
                    setTeamModalStatus(val)
                }
                else {
                    setFilterLoading(true)
                    setTeamModalStatus(val)
                    sc.getTeamById(challenge_id, activity.team_id).then((data) => {
                        setCurrentPage(1)
                        setTeamInfo(data)
                        setSelectedModalTeam(data.members)
                        setTotalModalTeam(data.members)
                        setFilterLoading(false)
                    })
                }
                break;
            case 'activity':
                setActivityModalStatus(val)
                setSelectedActivity(activities[0].id)
                setSelectedActivityLabel(activities[0].name)
                setMeasurements(activities[0].unit)
                setMeasurementSelectLabel(activities[0].unit[0].label)
                setMeasurementSelectValue(activities[0].unit[0].id)
                setActivityDate(new Date())
                setMeasurementValue('0')
                break;
            case 'tracking':
                if (val) {
                    setTrackingModalStatus(val)
                    setTrackingLoader(true)
                    getMyTrackings()
                } else {
                    setTrackingModalStatus(val)
                }
                break;
            case 'see_all_leaderboard':
                let t = leaderboard.slice(0, page_items)
                setSeeAllLeaderboardModalStatus(val)
                setModalLeaderboard(t)
                setFilterLoading(false)
                if (!val) {
                    setCurrentPage(1)
                    setModalLeaderboard(total_leaderboard)
                    setSearch_text('')
                }
                break;
            case 'see_all_team':
                let team = teamLeaderboard.slice(0, page_items)
                setSeeAllTeamModalStatus(val)
                setModalTeam(team)
                setFilterLoading(false)
                if (!val) {
                    setCurrentPage(1)
                    setModalTeam(teamLeaderboard)
                    setSearch_text('')
                }
                break;
            case 'create_team':

                setCreateTeamModalStatus(val)
                setTeamName(null)
                break;
            case 'my_team':
                setFilterLoading(true)
                setMyTeamModalStatus(val)

                sc.getMyTeam(challenge_id).then((data) => {
                    setCurrentPage(1)
                    setMyTeam(data.members)
                    setMyTeamInfo(data)
                    setModalMyTeam(data.members)
                    setFilterLoading(false)
                })
                break;
            case 'delete':
                setSelectedTracking(activity)
                setModalDeleteTracking(val)
                setTrackingModalStatus(!val)
                break;
            case 'team_info_popup':
                setCurrentPage(1)
                setModalTeamInfoPopuo(val)
                let tem = selected_total_modal_team.slice(0, page_items)
                setTotalModalTeam(tem)
                break;
            default:
                break;
        }
    }
    const setRecentlyJoinedFunction = (recently_joined) => {
        if (global_recently_joined.length > 0) {
            setRecentlyJoined(recently_joined)
        } else {
            setRecentlyJoined(recently_joined)
            setGlobalRecentlyJoined(recently_joined)
        }
    }
    const reloadRecentlyJoined = (val) => {
        sc.getRecentlyJoined(challenge_id, val).then((data) => {
            setRecentlyJoined(data)
        }).finally(() => {
            setRecentlyJoinedLoading(false)
        })
    }

    const formatData = (data) => {
        let new_date = new Date(data)
        let today = new Date()

        let differenceDays = datediff(today, new_date);
        let toStringDate = ''

        if (differenceDays === 0) {
            toStringDate = getTranslationByKey('CMC.TODAY');
        } else if (differenceDays === -1) {
            toStringDate = getTranslationByKey('CMC.YESTERDAY');
        } else {
            toStringDate = new_date.getDate().toString() + "/" + (new_date.getMonth() + 1).toString() + "/" + new_date.getFullYear().toString()
        }

        return toStringDate
    }

    const getData = (date) => {
        let new_date = new Date(date)
        let toStringDate = new_date.getFullYear().toString() + '-' + ("0" + (new_date.getMonth() + 1)).slice(-2).toString() + "-" + ("0" + new_date.getDate()).slice(-2).toString()
        return toStringDate
    }

    const handleActivitySelect = (event) => {
        setActivityLoader(true)
        setTimeout(() => {
            setSelectedActivity(event.value)
            setSelectedActivityLabel(event.label)

            getMeasurements(event.value)
        }, 200);
    }

    const getMeasurements = (value) => {

        let measurements = []
        activities.map((a) => {
            if (a.id == value) {
                measurements = a.unit
            }
        })
        setMeasurements(measurements)
        setMeasurementSelectLabel(measurements[0].unit)
        setMeasurementSelectValue(measurements[0].id)
        setActivityLoader(false)

    }

    const handleMeasurementSelectValue = (event) => {
        setMeasurementSelectLabel(event.label)
        setMeasurementSelectValue(event.value)

    }
    const getMyTrackings = () => {
        sc.getMyTrackings(challenge_id).then((data) => {
            setTracking(data)
        }).finally(() => {
            setTrackingLoader(false)
        })

    }
    const createTeam = () => {
        let t = { name: team_name }
        setTeamLoading(true)
        let filters_team = {
            "time": today_team ? 'day' : (week_team ? 'week' : '')
        }
        sc.createTeam(challenge_id, t).then((team) => {
            setTeamInfo(team)
            setMyTeam(team)
            sc.getTeamLeaderboard(challenge_id, filters_team).then((data) => {
                setModalTeam(data)
                setTeamLeaderboard(data)
            }).finally(() => {
                props.userReload()
                setTeamLoading(false)
                setFilterLoading(false)
            })
            //handleModal(false, 'create_team')
            setTeamCreated(true)
        }).catch(() => {
            setTeamLoading(false)

        })
    }

    const addActivity = () => {

        let new_date = new Date(activity_date)
        let toStringDate = new_date.getFullYear().toString() + "-" + (new_date.getMonth() + 1).toString() + "-" + new_date.getDate().toString()

        let body = {
            "challenge_id": challenge_id,
            "activity_id": selectedActivity,
            "value": parseFloat(measurementValue),
            "unit_id": measurementSelectValue,
            "date": toStringDate
        }



        let filters = {
            "is_global": global,
            "time": today == true ? 'day' : (week == true ? 'week' : '')
        }
        let filters_team = {
            "time": today_team ? 'day' : (week_team ? 'week' : '')
        }
        if (!measurementValue) {
            UseAlertMessageLogin(400, "Inserire il valore dell'attività")
        } else if (measurementValue <= 0) {
            UseAlertMessageLogin(400, "Inserire un valore maggiore di 0")
        } else if (!measurementValue.match(/^-?\d+$/) && !measurementValue.match(/^\d+\.\d+$/)) {
            UseAlertMessageLogin(400, "Inserire un valore valido")
        } else {
            setLeaderboardLoading(true)
            setRegionLoading(true)
            setTeamLoading(true)
            setFilterLoading(true)
            sc.addActivity(body).then((data) => {
                getBalance()
                getChallengeInfo()
                handleModal(false, 'activity')
                filters = {
                    "is_global": global,
                    "time": today ? 'day' : (week ? 'week' : '')
                }
                sc.getTeamLeaderboard(challenge_id, filters_team).then((data) => {
                    setModalTeam(data)
                    setTeamLeaderboard(data)
                }).finally(() => {
                    props.userReload()
                    setTeamLoading(false)
                    setFilterLoading(false)
                })
                getLeaderboard(challenge_id, filters)
            })

        }
    }

    const deleteActivity = () => {
        setLeaderboardLoading(true)
        setRegionLoading(true)
        let filters = {
            "is_global": global,
            "time": today == true ? 'day' : (week == true ? 'week' : '')
        }
        let filters_team = {
            "time": today_team ? 'day' : (week_team ? 'week' : '')
        }
        setTeamLoading(true)
        sc.deleteActivity(selectedTracking.id).then((data) => {
            getBalance()
            getChallengeInfo()
            sc.getTeamLeaderboard(challenge_id, filters_team).then((data) => {
                setModalTeam(data)
                setTeamLeaderboard(data)
            }).finally(() => {
                props.userReload()
                setTeamLoading(false)
                setFilterLoading(false)
            })
            getLeaderboard(challenge_id, filters)
            handleModal(false, 'delete', '')
            handleModal(false, 'tracking')
        })
    }

    const getChallengeLeaderboard = (filters) => {
        sc.getChallengeLeaderboard(challenge_id, filters).then((data) => {
            setLeaderboard(data)
        }).finally(() => {
            setLeaderboardLoading(false)
        })
    }
    const getTime = (time) => {
        return new Date(time).getTime()
    }

    const getBalance = () => {
        sc.getBalance(challenge_id).then((data) => {
            setBalance(data)
        }).finally(() => {
            setLoading(false)
        })
    }

    const getChallengeInfo = () => {
        sc.getChallengeInfo(challenge_id).then((data) => {
            setChallenge(data)
        })
    }
    const joinToTeam = (team) => {
        let filters_team = {
            "time": today_team ? 'day' : (week_team ? 'week' : '')
        }
        setTeamLoading(true)
        setFilterLoading(true)
        sc.joinToTeam(challenge_id, team.id).then(() => {
            sc.getTeamLeaderboard(challenge_id, filters_team).then((data) => {
                setModalTeam(data)
                setTeamLeaderboard(data)
            }).finally(() => {
                props.userReload()
                // setSeeAllTeamModalStatus(false)
                setTimeout(() => {
                    setTeamLoading(false)
                    setFilterLoading(false)
                    //  handleModal(false, 'join_team')
                    //handleModal(false, 'see_all_team')
                }, 500);
            })
        }).catch(() => {
            setModalTeam(false)
            setTeamLoading(false)
            setFilterLoading(false)
        })
    }
    const datediff = (first, second) => {
        return Math.ceil((second - first) / (1000 * 60 * 60 * 24));
    }
    const copy = () => {
        navigator.clipboard.writeText(window.location.origin + '/team/' + my_team_info.id + '/')
        UseAlertMessageLogin(200, 'Link copied!')
    }
    const copyTeamLink = () => {
        navigator.clipboard.writeText(window.location.origin + '/team/' + team_info.id + '/')
        UseAlertMessageLogin(200, 'Link copied!')
    }
    const getTranslationByKey = (key) => {
        let translation = ''
        let translation_en = ''
        if (sessionStorage.getItem('translation')) {
            translation = JSON.parse(sessionStorage.getItem('translation'))
            translation_en = JSON.parse(sessionStorage.getItem('translation_en'))
            return translation[key] && translation[key].v ? translation[key].v : (translation_en[key] && translation_en[key].v ? translation_en[key].v : key)
        } else {
            return key
        }
    }

    let lang = localStorage.getItem('language')
    let join_challenge_button = 'join_challenge_button'
    let joined_challenge_button_class = 'joined_challenge_button'
    let balance = 'balance'
    let box_title_red = 'box_title_red text_center'
    let left_box_line_button = 'left_box_line_button'
    let workplace_logo = 'workplace_logo'

    if (lang === 'ko' || lang === 'es-MX' || lang === 'pt-BR') {
        join_challenge_button = 'join_challenge_button_ko'
    }
    if (lang === 'ja')
        join_challenge_button = 'join_challenge_button_ja'


    if (lang === 'ko') {
        joined_challenge_button_class = 'joined_challenge_button_ko'
        balance = 'balance_ko'
    }

    if (lang === 'ja') {
        box_title_red = 'box_title_red_ja text_center'
        joined_challenge_button_class = 'joined_challenge_button_ja'
        left_box_line_button = 'left_box_line_button_ja'
        workplace_logo = 'workplace_logo_ja'
    }

    if (lang === 'pt-BR') {
        workplace_logo = 'workplace_logo_pt'
        joined_challenge_button_class = 'joined_challenge_button_pt'
    }

    const customStylesSelect = {
        control: (base, state) => ({
            ...base,
            background: "#fff",
            color: "#000",
            border: 0,
            // Overwrittes the different states of border
            borderColor: "#fff !important",
            // Removes weird border around container
            boxShadow: "#fff !important",

        }),
        menuList: base => ({
            ...base,
            background: "#fff",
            // kill the white space on first and last option
            padding: 0,
            "overflow-x": "hidden",
            height: "220px",
            "::-webkit-scrollbar": {
                width: "3px",
                background: "#b8b8b8",
                "border-radius": "32px"
            },
            "::-webkit-scrollbar-thumb": {
                "background-color": "black",
                "border- radius": "32px",
            },
        })
    };

    const customStylesMeasurementSelect = {
        control: (base, state) => ({
            ...base,
            background: "#fff",
            color: "#000",
            border: 0,
            // Overwrittes the different states of border
            borderColor: "#fff !important",
            // Removes weird border around container
            boxShadow: "#fff !important",

        }),
        menuList: base => ({
            ...base,
            background: "#fff",
            // kill the white space on first and last option
            padding: 0,
            "overflow-x": "hidden",
            height: "auto",
            "max-height": "200px",
            "::-webkit-scrollbar": {
                width: "3px",
                background: "#b8b8b8",
                "border-radius": "32px"
            },
            "::-webkit-scrollbar-thumb": {
                "background-color": "black",
                "border- radius": "32px",
            },
        })
    };

    let customModalStyles = {

        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            width: 'auto',
            height: '600px',
            marginRight: '-50%',
            padding: '30px',
            transform: 'translate(-50%, -50%)',
            'z-index': 99,
            transition: "opacity 20ms ease-in -out",
            "border-radius": "30px"
        },

    };
    let seeAllModalStyles = {

        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            width: '500px',
            height: '600px',
            marginRight: '-50%',
            padding: '30px',
            transform: 'translate(-50%, -50%)',
            'z-index': 99,
            transition: "opacity 20ms ease-in -out",
            "border-radius": "30px",
            overflow: 'hidden'
        },

    };

    let customModalStylesTracking = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            width: '400px',
            height: '600px',
            marginRight: '-50%',
            padding: '30px',
            transform: 'translate(-50%, -50%)',
            'z-index': 99,
            transition: "opacity 20ms ease-in -out",
            "border-radius": "30px",
            overflow: 'hidden'
        },

    };

    //homepage team list
    let team_leaderboard_list = ''
    if (teamLeaderboard.length > 0)
        team_leaderboard_list = teamLeaderboard.map((r, index) =>
        (index < 10 ? <div className="user_row">

            <div className="user_row user_row_width">
                <div className="user_rank" Style={"margin-bottom: 0px; "}>
                    <div className="user_rank_index">{r.position}</div>
                </div>
                <div className="team_cont">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
                        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                    </svg>
                    <div className="team_num" Style={' margin-top: 2px;'}>{r.head_count ?? 0}</div>
                </div>
                <div className="user_info">
                    <div className="user_name pointer"><span onClick={() => handleModal(true, 'join_team', r)}>{r.team_name}</span></div>
                </div>

                <div Style={'margin-left: auto'}>
                    <div className="flex">
                        {props.user && props.user.modules && props.user.modules.gsc && props.user.modules.gsc.challenges && props.user.modules.gsc.challenges[0] && props.user.modules.gsc.challenges[0].my_team || !joined || !user.modules.gsc.challenges[0].is_active ? '' :
                            <div className="join_link " onClick={() => handleModal(true, 'join_team', r)}>
                                Join
                            </div>}
                        <div className="coins">
                            <div className="coins_value">{r.coins}</div>
                            <div className="coins_label location">{getTranslationByKey('CMC.COINS')}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div> : '')
        )

    //homepage leaderboard user list
    let leaderboard_users = ''
    if (leaderboard.length > 0) {
        leaderboard_users = leaderboard.map((u, index) =>
            (index != leaderboard.length || index == 0) && index < 10 ?
                <div className="user_row">
                    <div className="user_rank">
                        <div className="user_rank_index">{index + 1}</div>
                    </div>
                    <div className="user_rank_img" style={{ backgroundImage: 'url(' + u.user.image_url + ')' }}>
                        {!u.user.image_url ? <div className="user_rank_text">{u.user.first_name[0]}{u.user.last_name[0]}</div> : ''}
                    </div>
                    <div className="name_cont">
                        <div className="flex">
                            <div className="user_name">{u.user.first_name} <span Style="text-transform: uppercase">{u.user.last_name}</span></div>
                            {/* <div className="user_surname"> </div> */}
                        </div>
                        <div className="location">{u.user.location}</div>
                    </div>
                    <div className="coins">
                        <div className="coins_value">{u.coins}</div>
                        <div className="coins_label location">{getTranslationByKey('CMC.COINS')}</div>
                    </div>
                </div> : ''

        )
    }

    //homepage leaderboard region list
    let region_leaderboard_list = ''
    if (region_leaderboard.length > 0) {
        region_leaderboard_list = region_leaderboard.map((r, index) =>
            index < 10 ?
                <div className="user_row">

                    <div className="user_row user_row_width">
                        <div className="user_rank" Style={"margin-bottom: 0px; margin-top: 2px;"}>
                            <div className="user_rank_index">{r.position}</div>
                        </div>
                        <div className="user_info">
                            <div className="user_name"><span Style="text-transform: uppercase">{r.region}</span></div>
                        </div>
                        <div className="user_access">
                            {r.date}
                        </div>

                        <div className="coins" Style={'margin-left: auto'}>
                            <div className="coins_value">{r.coins}</div>
                            <div className="coins_label location">{getTranslationByKey('CMC.COINS')}</div>
                        </div>
                    </div>
                </div> : ''

        )
    }
    //modal user popup search/not search/no data
    let leaderboard_users_modal = ''
    if (search_text === '' && modal_leaderboard.length > 0) {
        leaderboard_users_modal = modal_leaderboard.map((u, index) =>
            <div className="user_row">
                <div className="user_rank">
                    <div className="user_rank_index">{currentPage < 1 ? index + 1 : (index + 1) + (page_items * (currentPage - 1))}</div>
                </div>
                <div className="name_cont_modal">
                    <div className="flex">
                        <div className="user_name">{u.user.first_name} <span Style="text-transform: uppercase">{u.user.last_name}</span></div>
                        {/* <div className="user_surname">{u.user.last_name} </div>*/}
                    </div>
                    <div className="location">{u.user.location}</div>
                </div>
                <div className="coins">
                    <div className="coins_value">{u.coins}</div>
                    <div className="coins_label location">{getTranslationByKey('CMC.COINS')}</div>
                </div>
            </div>
        )
    }
    else if (search_text && filtered_leaderboard.length > 0) {
        leaderboard_users_modal = filtered_leaderboard.map((u, index) =>
            <div className="user_row">
                <div className="user_rank">
                    <div className="user_rank_index">{currentPage < 1 ? index + 1 : (index + 1) + (page_items * (currentPage - 1))}</div>
                </div>
                <div className="user_rank_img" style={{ backgroundImage: 'url(' + u.user.image_url + ')' }}>
                    {!u.user.image_url ? <div className="user_rank_text">{u.user.first_name[0]}{u.user.last_name[0]}</div> : ''}
                </div>
                <div className="name_cont_modal">
                    <div className="flex">
                        <div className="user_name">{u.user.first_name} <span Style="text-transform: uppercase">{u.user.last_name}</span></div>
                        {/*<div className="user_surname">{u.user.last_name} </div>*/}
                    </div>
                    <div className="location">{u.user.location}</div>
                </div>
                <div className="coins">
                    <div className="coins_value">{u.coins}</div>
                    <div className="coins_label location">{getTranslationByKey('CMC.COINS')}</div>
                </div>
            </div>)
    }
    else if (filtered_leaderboard.length == 0 || modal_leaderboard.length > 0) {
        leaderboard_users_modal = <div className="user_row">
            <div className="gucci_text_bold">{getTranslationByKey('CMC.NODATAAVAILABLE')}</div>
        </div>
    }

    //list of my team user popup search/not search/no data
    let my_team_users_modal = ''
    if (search_text === '' && my_team.length > 0) {
        my_team_users_modal = my_team.map((u, index) =>
            index < 10 ?
                <div className="user_row">
                    {/*}  <div className="user_rank">
                        <div className="user_rank_index">{currentPage < 1 ? index + 1 : (index + 1) + (page_items * (currentPage - 1))}</div>
</div>*/}
                    <div className="user_rank_img" style={{ backgroundImage: 'url(' + (u.image_url ?? '') + ')' }}>
                        {!u.image_url ? <div className="user_rank_text">{u.first_name[0]}{u.last_name[0]}</div> : ''}
                    </div>
                    {props.user && props.user.modules && props.user.modules.gsc && props.user.modules.gsc.challenges && props.user.modules.gsc.challenges[0] && props.user.modules.gsc.challenges[0].my_team ? '' :
                        <div className="join_link " onClick={() => joinToTeam(u)}>
                            Join
                        </div>}
                    <div className="name_cont_modal">
                        <div className="flex">
                            <div className="user_name">{u.first_name} <span Style="text-transform: uppercase">{u.last_name}</span></div>
                            {/*<div className="user_surname">{u.user.last_name} </div>*/}
                        </div>
                        <div className="location">{u.location}</div>
                    </div>
                    <div className="coins">
                        <div className="coins_value">{u.coins}</div>
                        <div className="coins_label location">{getTranslationByKey('CMC.COINS')}</div>
                    </div>
                </div> : ''
        )
    }
    else if (search_text && filtered_my_team.length > 0) {
        my_team_users_modal = filtered_my_team.map((u, index) =>
            index < 10 ?
                <div className="user_row">
                    {/*   <div className="user_rank">
                        <div className="user_rank_index">{currentPage < 1 ? index + 1 : (index + 1) + (page_items * (currentPage - 1))}</div>
                    </div>*/}
                    <div className="user_rank_img" style={{ backgroundImage: 'url(' + (u.image_url ?? '') + ')' }}>
                        {!u.image_url ? <div className="user_rank_text">{u.first_name[0]}{u.last_name[0]}</div> : ''}
                    </div>

                    <div className="name_cont_modal">
                        <div className="flex">
                            <div className="user_name">{u.first_name} <span Style="text-transform: uppercase">{u.last_name}</span></div>
                            {/*<div className="user_surname">{u.user.last_name} </div>*/}
                        </div>
                        <div className="location">{u.location}</div>
                    </div>
                    <div className="coins">
                        <div className="coins_value">{u.coins}</div>
                        <div className="coins_label location">{getTranslationByKey('CMC.COINS')}</div>
                    </div>
                </div> : '')
    }
    else if (filtered_my_team.length == 0 || modal_my_team.length > 0) {
        my_team_users_modal = <div className="user_row">
            <div className="gucci_text_bold">{getTranslationByKey('CMC.NODATAAVAILABLE')}</div>
        </div>
    }


    //list of all teams popup search/not search/no data
    let team_users_modal = ''
    if (search_text === '' && modal_team.length > 0) {
        team_users_modal = modal_team.map((u, index) =>
            <div className="user_row">
                <div className="user_rank">
                    <div className="user_rank_index">{currentPage < 1 ? index + 1 : (index + 1) + (page_items * (currentPage - 1))}</div>
                </div>
                <div className="team_cont">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
                        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                    </svg>
                    <div className="team_num" >{u.head_count ?? 0}</div>
                </div>

                <div className="name_cont">
                    <div className="flex">
                        <div className="user_name pointer" onClick={() => handleModal(true, 'join_team', u)}>{u.team_name} </div>
                    </div>
                </div>
                <div Style={'margin-left: auto'}>
                    <div className="flex">
                        {props.user && props.user.modules && props.user.modules.gsc && props.user.modules.gsc.challenges && props.user.modules.gsc.challenges[0] && props.user.modules.gsc.challenges[0].my_team || !joined ? '' :
                            <div className="join_link " onClick={() => handleModal(true, 'join_team', u)}>
                                Join
                            </div>}
                        <div className="coins">
                            <div className="coins_value">{u.coins}</div>
                            <div className="coins_label location">{getTranslationByKey('CMC.COINS')}</div>
                        </div>
                    </div>
                </div>


            </div>
        )
    }
    else if (search_text && filtered_teams.length > 0) {
        team_users_modal = filtered_teams.map((u, index) =>
            <div className="user_row">
                <div className="user_rank">
                    <div className="user_rank_index">{index + 1}</div>
                </div>
                <div className="team_cont">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
                        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                    </svg>
                    <div className="team_num" >{u.head_count ?? 0}</div>
                </div>

                <div className="name_cont">
                    <div className="flex">
                        <div className="user_name">{u.team_name} </div>
                    </div>
                    <div>

                        {props.user && props.user.modules && props.user.modules.gsc && props.user.modules.gsc.challenges && props.user.modules.gsc.challenges[0] && props.user.modules.gsc.challenges[0].my_team || !joined ? '' :
                            <div className="join_link " onClick={() => joinToTeam(u)}>
                                Join
                            </div>}

                    </div>
                </div>

            </div>)
    }
    else if (filtered_my_team.length == 0 || modal_team.length > 0) {
        team_users_modal = <div className="user_row">
            <div className="gucci_text_bold">{getTranslationByKey('CMC.NODATAAVAILABLE')}</div>
        </div>
    }
    //list of users of the selected team popup search/not search/no data
    let selected_team_users_modal = ''
    if (search_text === '' && selected_modal_team.length > 0) {
        selected_team_users_modal = selected_modal_team.map((u, index) =>
            index < 10 ?
                <div className="user_row">
                    <div className="user_rank_img" style={{ backgroundImage: 'url(' + (u.image_url ?? '') + ')' }}>
                        {!u.image_url ? <div className="user_rank_text">{u.first_name[0]}{u.last_name[0]}</div> : ''}
                    </div>

                    <div className="name_cont_modal">
                        <div className="flex">
                            <div className="user_name">{u.first_name} <span Style="text-transform: uppercase">{u.last_name}</span></div>
                        </div>
                    </div>

                    <div className="coins">
                        <div className="coins_value">{u.coins}</div>
                        <div className="coins_label location">{getTranslationByKey('CMC.COINS')}</div>
                    </div>
                </div> : ''
        )
    }
    else if (search_text && filtered_selected_team_users.length > 0) {
        selected_team_users_modal = filtered_selected_team_users.map((u, index) =>
            index < 10 ?
                <div className="user_row">
                    <div className="user_rank">
                        <div className="user_rank_index">{currentPage < 1 ? index + 1 : (index + 1) + (page_items * (currentPage - 1))}</div>
                    </div>
                    <div className="user_rank_img" style={{ backgroundImage: 'url(' + (u.image_url ?? '') + ')' }}>
                        {!u.image_url ? <div className="user_rank_text">{u.first_name[0]}{u.last_name[0]}</div> : ''}
                    </div>

                    <div className="name_cont_modal">
                        <div className="flex">
                            <div className="user_name">{u.first_name} <span Style="text-transform: uppercase">{u.last_name}</span></div>
                            {/*<div className="user_surname">{u.user.last_name} </div>*/}
                        </div>

                    </div>
                    <div className="coins">
                        <div className="coins_value">{u.coins}</div>
                        <div className="coins_label location">{getTranslationByKey('CMC.COINS')}</div>
                    </div>
                </div> : '')
    }
    else if (selected_team_users_modal.length == 0 || selected_modal_team.length > 0) {
        selected_team_users_modal = <div className="user_row">
            <div className="gucci_text_bold">{getTranslationByKey('CMC.NODATAAVAILABLE')}</div>
        </div>
    }

    let activity_options_new = []
    if (activities.length > 0)
        activities.map((a) => activity_options_new.push({ "value": a.id, "label": a.name }))

    let measurement_option_new = []
    if (measurements.length > 0)
        measurements.map((m) => measurement_option_new.push({ "value": m.id, "label": m.label }))

    let regex = new RegExp("^[0-9]+$")
    let regex2 = new RegExp("^[0-9]+\\.{0,1}[0-9]+$")
    return (<div className="background">
        <Navbar user={user} loading={setLoading} setPrivacyPolicy={setIsPrivacyPolicy} />
        {loading ?
            <div className="loader-cont"><div className="loader"> </div></div>
            :
            is_privacy_policy ?

                <div className="ppolicy_global_container">
                    <div className="ppolicy_container flex" onClick={() => setIsPrivacyPolicy(false)}>

                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="black" class="bi bi-chevron-left back_left_bar" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                        </svg>
                        <div className="back_button_ppolicy">Back</div>
                        {/**/}
                    </div>
                    <div className="text_center">
                        <div className="ppolicy_title">{getTranslationByKey('CMC.PRIVACYPOLICY')}</div>
                        <div className="ppolicy_text gucci_text mt-3"
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(getTranslationByKey('CMC.PRIVACYPOLICY.TEXT')) }}>

                        </div>
                    </div>
                </div>

                :
                <div className={props.scroll ? "boxes_container boxes_container_animation flex" : 'boxes_container flex'}>
                    {/* first column */}
                    <div className="column">
                        <div Style="margin-bottom: 10px;">
                            <div className="about_container">
                                {/*
                        <div className="unhcr_logo_small_container">
                            <div id="unhcr_logo_small" Style={"background-image: url('" + challenge.image_url + "');"}></div>
                        </div>
                         */}
                                <div className="text_center">
                                    <div className="logo_gucci_small_container"></div>
                                    <div className="about_title">{getTranslationByKey('CMC.ABOUT.CHALLANGE')}</div>
                                    <div className="box_text_ngo_challenge" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(challenge && challenge.description ? challenge.description : '') }}>
                                        { }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div Style="margin-bottom: 10px; position: relative;">
                            <div className="about_container">
                                {/*
                         */}
                                <div className="unhcr_logo_small_container">
                                    <div id="unhcr_logo_small"></div>
                                </div>
                                <div className="text_center">
                                    <div className="about_title">{getTranslationByKey('CMC.ABOUT.NGO')}</div>
                                    <div className="box_text_ngo_challenge" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(challenge && challenge.ngo ? challenge.ngo.description : '') }}>

                                    </div>
                                    <div className="box_text_ngo_challenge box_subtext_ngo_challenge">*UNICEF does not endorse any company, brand, product or service</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* second column */}
                    <div className="column">
                        <div className="about_container_red" Style="margin-bottom: 10px;">
                            <React.Fragment>
                                <div className="text_center">
                                    <div className={box_title_red} Style={lang === 'pt-BR' ? "line-height: 40px; margin-bottom: 10px; color: #000 !important;" : "color: #000 !important;"}>{getTranslationByKey('CMC.UNICEF.SPORTDEV.TITLE')}</div>
                                </div>
                                <ul>
                                    <div className="lines_container">
                                        <div className="left_box_line_container flex">
                                            <div className="box_text_blu flex">
                                                <div className="flex align_center">
                                                    <div className="box_icon_blu ">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white" class="bi bi-graph-up-arrow" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M0 0h1v15h15v1H0zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5" />
                                                        </svg>
                                                    </div>
                                                    <Markdown>
                                                        {getTranslationByKey('CMC.UNICEF.SPORTDEV.LABEL1')}
                                                    </Markdown>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="left_box_line_container flex">
                                            <div className="box_text_blu ">
                                                <div className="flex align_center">
                                                    <div className="box_icon_blu ">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white" class="bi bi-buildings" viewBox="0 0 16 16">
                                                            <path d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022M6 8.694 1 10.36V15h5zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5z" />
                                                            <path d="M2 11h1v1H2zm2 0h1v1H4zm-2 2h1v1H2zm2 0h1v1H4zm4-4h1v1H8zm2 0h1v1h-1zm-2 2h1v1H8zm2 0h1v1h-1zm2-2h1v1h-1zm0 2h1v1h-1zM8 7h1v1H8zm2 0h1v1h-1zm2 0h1v1h-1zM8 5h1v1H8zm2 0h1v1h-1zm2 0h1v1h-1zm0-2h1v1h-1z" />
                                                        </svg>
                                                    </div>
                                                    <Markdown>
                                                        {getTranslationByKey('CMC.UNICEF.SPORTDEV.LABEL2')}
                                                    </Markdown>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="left_box_line_container flex">
                                            <div className="box_text_blu ">
                                                <div className="flex align_center">
                                                    <div className="box_icon_blu ">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white" class="bi bi-eyeglasses" viewBox="0 0 16 16">
                                                            <path d="M4 6a2 2 0 1 1 0 4 2 2 0 0 1 0-4m2.625.547a3 3 0 0 0-5.584.953H.5a.5.5 0 0 0 0 1h.541A3 3 0 0 0 7 8a1 1 0 0 1 2 0 3 3 0 0 0 5.959.5h.541a.5.5 0 0 0 0-1h-.541a3 3 0 0 0-5.584-.953A2 2 0 0 0 8 6c-.532 0-1.016.208-1.375.547M14 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0" />
                                                        </svg>
                                                    </div>
                                                    <Markdown>
                                                        {getTranslationByKey('CMC.UNICEF.SPORTDEV.LABEL3')}
                                                    </Markdown>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="left_box_line_container flex">
                                            <div className="box_text_blu ">
                                                <div className="flex align_center">
                                                    <div className="box_icon_blu ">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white" class="bi bi-person-arms-up" viewBox="0 0 16 16">
                                                            <path d="M8 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                                                            <path d="m5.93 6.704-.846 8.451a.768.768 0 0 0 1.523.203l.81-4.865a.59.59 0 0 1 1.165 0l.81 4.865a.768.768 0 0 0 1.523-.203l-.845-8.451A1.5 1.5 0 0 1 10.5 5.5L13 2.284a.796.796 0 0 0-1.239-.998L9.634 3.84a.7.7 0 0 1-.33.235c-.23.074-.665.176-1.304.176-.64 0-1.074-.102-1.305-.176a.7.7 0 0 1-.329-.235L4.239 1.286a.796.796 0 0 0-1.24.998l2.5 3.216c.317.316.475.758.43 1.204Z" />
                                                        </svg>
                                                    </div>
                                                    <Markdown>
                                                        {getTranslationByKey('CMC.UNICEF.SPORTDEV.LABEL4')}
                                                    </Markdown>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="left_box_line_container flex">
                                            <div className="box_text_blu ">
                                                <div className="flex align_center">
                                                    <div className="box_icon_blu "><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white" class="bi bi-bicycle" viewBox="0 0 16 16">
                                                        <path d="M4 4.5a.5.5 0 0 1 .5-.5H6a.5.5 0 0 1 0 1v.5h4.14l.386-1.158A.5.5 0 0 1 11 4h1a.5.5 0 0 1 0 1h-.64l-.311.935.807 1.29a3 3 0 1 1-.848.53l-.508-.812-2.076 3.322A.5.5 0 0 1 8 10.5H5.959a3 3 0 1 1-1.815-3.274L5 5.856V5h-.5a.5.5 0 0 1-.5-.5m1.5 2.443-.508.814c.5.444.85 1.054.967 1.743h1.139zM8 9.057 9.598 6.5H6.402zM4.937 9.5a2 2 0 0 0-.487-.877l-.548.877zM3.603 8.092A2 2 0 1 0 4.937 10.5H3a.5.5 0 0 1-.424-.765zm7.947.53a2 2 0 1 0 .848-.53l1.026 1.643a.5.5 0 1 1-.848.53z" />
                                                    </svg></div>
                                                    <Markdown>
                                                        {getTranslationByKey('CMC.UNICEF.SPORTDEV.LABEL5')}
                                                    </Markdown>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="left_box_line_container flex">
                                            <div className="box_text_blu ">
                                                <div className="flex align_center">
                                                    <div className="box_icon_blu ">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white" class="bi bi-stars" viewBox="0 0 16 16">
                                                            <path d="M7.657 6.247c.11-.33.576-.33.686 0l.645 1.937a2.89 2.89 0 0 0 1.829 1.828l1.936.645c.33.11.33.576 0 .686l-1.937.645a2.89 2.89 0 0 0-1.828 1.829l-.645 1.936a.361.361 0 0 1-.686 0l-.645-1.937a2.89 2.89 0 0 0-1.828-1.828l-1.937-.645a.361.361 0 0 1 0-.686l1.937-.645a2.89 2.89 0 0 0 1.828-1.828zM3.794 1.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387A1.73 1.73 0 0 0 4.593 5.69l-.387 1.162a.217.217 0 0 1-.412 0L3.407 5.69A1.73 1.73 0 0 0 2.31 4.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387A1.73 1.73 0 0 0 3.407 2.31zM10.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.16 1.16 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.16 1.16 0 0 0-.732-.732L9.1 2.137a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732z" />
                                                        </svg>
                                                    </div>
                                                    <Markdown>
                                                        {getTranslationByKey('CMC.UNICEF.SPORTDEV.LABEL6')}
                                                    </Markdown>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </ul>

                            </React.Fragment>
                        </div>
                        <div>
                            <div className="about_container_blu" Style="margin-bottom: 10px;" id='coin'>
                                {joined ?

                                    <div className="text_center">
                                        <div className="about_container_header flex">
                                            <div className="number_circle">{my_position > 0 ? my_position : ''}</div>
                                            <div className="user_name_white">{user.firstname} {user.lastname}</div>
                                        </div>
                                        <div id="coin_container"></div>
                                        <div className="flex" Style={"align-items: center;justify-content: center;"}>
                                            <div id={balance} className="flex">{getTranslationByKey('CMC.BALANCECOINS')}: {balance_data.total_balance}</div>
                                            <div id="info_popup_icon" onClick={() => setInfoPopup(true)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" class="bi bi-info-circle pointer" viewBox="0 0 16 16">
                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="about_container_header flex" Style="width: 60%;">
                                            <div id="my_coins">{balance_data.today_coins}</div>
                                            <div id="coins_earned">{getTranslationByKey('CMC.COINSEARNEDTODAY')}</div>
                                        </div>
                                    </div>
                                    :
                                    <React.Fragment>
                                        <div className={box_title_red}>{getTranslationByKey('CMC.HOW.TITLE')}</div>
                                        <div className="text_container flex">
                                            <div className="number_circle">1</div>
                                            <div className="box_text ">{getTranslationByKey('CMC.HOW.LABLE1')}</div>
                                        </div>
                                        <div className="text_container flex">
                                            <div className="number_circle">2</div>
                                            <div className="box_text ">{getTranslationByKey('CMC.HOW.LABLE2')}</div>
                                            <div className="info_ico" onClick={() => setInfoPopup(true)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" class="bi bi-info-circle pointer" viewBox="0 0 16 16">
                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="text_container flex">
                                            <div className="number_circle">3</div>
                                            <div className="box_text ">{getTranslationByKey('CMC.HOW.LABLE3')}</div>
                                        </div>


                                        <div className="flex" Style="justify-content: center;">
                                            {/**/}
                                            <div id="u266_claim">{getTranslationByKey('CMC.QUOTEMESSAGE')}</div>

                                        </div>
                                    </React.Fragment>
                                }

                                <div >
                                    {challenge && getTime(challenge.from) <= new Date().getTime() && new Date().getTime() <= getTime(challenge.to) ?
                                        (joined ?
                                            <div className="flex">
                                                <div className={joined_challenge_button_class} onClick={() => handleModal(true, 'activity')}>{getTranslationByKey('CMC.ADDACTIVITY')}</div>
                                                <div className={joined_challenge_button_class} onClick={() => handleModal(true, 'tracking')}>{getTranslationByKey('CMC.MYTRACKING')}</div>
                                            </div>
                                            :
                                            <div id={join_challenge_button} onClick={() => handleModal(true, 'join')}>{getTranslationByKey('CMC.JOINCHALLANGE')}</div>
                                        )
                                        :
                                        <div className={joined_challenge_button_class} onClick={() => handleModal(true, 'tracking')}>{getTranslationByKey('CMC.MYTRACKING')}</div>

                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* third column */}
                    <div className="column">

                        <div className="about_container_blu unhcr_challenge">
                            <div id="u280">
                                <div className="photo_credits">© UNICEF/UNI548764/Bothma</div>
                            </div>
                            <div Style="margin:5px 10px 0px 10px; height: 45px;" className="flex">
                                <div className="box_title_blu" Style="margin-top: 8px;">{challenge.name}</div>
                            </div>
                            <ProgressBar value={challenge ? challenge.progress : (parseInt(challenge.target_value) / 2)} joined={joined} max={challenge ? parseInt(challenge.target_value) : 1000} />
                            <div className="flex" Style="align-items: center; justify-content: center;">
                                <div className="left_box_line_container under_progressbar">
                                    <div className="box_little_title" Style="margin-left: 0px">{getTranslationByKey('CMC.WEEKLYGOAL')}</div>
                                    <div className="box_little_title_2" Style="margin-left: 0px">{challenge ? parseInt(challenge.target_value) : ''} {getTranslationByKey('CMC.COINS')}</div>
                                </div>
                                <div className="flex pb_pd" Style="width: 190px;">
                                    {global_recently_joined.length > 0 ?
                                        global_recently_joined.map((leader) => (
                                            leader.image_url ?
                                                <div className="people_circle_user" Style={"background-image: url('" + leader.image_url + "'); background-size: cover;"}></div>
                                                :
                                                <div className="people_circle_user">
                                                    <div className="people_circle_text">{leader.first_name[0]}{leader.last_name[0]}</div>
                                                </div>
                                        ))
                                        :
                                        <React.Fragment>
                                            <div className="people_circle"></div>
                                            <div className="people_circle"></div>
                                            <div className="people_circle"></div>
                                            <div className="people_circle"></div>
                                        </React.Fragment>
                                    }

                                </div>
                                <div className="pb_pd" Style="width: 100px; text-align: right;">
                                    <div className="box_little_title" Style="margin-bottom: 5px;">{challenge ? challenge.members_count : 0}</div>
                                    <div className="box_little_title" > {getTranslationByKey('CMC.COLLEAGUES')}</div>
                                </div>
                            </div>

                            <br />
                        </div>

                        <div className="publish_container" Style="margin-top: 10px;">

                            <div className="left_box_line_publish">
                                {window.location.hostname == 'changemakerschallenge.gucci.cn' ?
                                    <div id="publish_text" >{getTranslationByKey('CMC.PUBLISH.TEXT').replace('Workplace', 'Viva Engage – Gucci community')}</div>
                                    :
                                    <div id="publish_text" >{getTranslationByKey('CMC.PUBLISH.TEXT')}</div>
                                }
                                <div className={left_box_line_button} Style="background-color: #fff;" onClick={() => setPublishPopup(true)}>
                                    {window.location.hostname == 'changemakerschallenge.gucci.cn' ?
                                        ''
                                        :
                                        <div id={workplace_logo}></div>
                                    }
                                    <div Style="margin-left: 5px; font-size: 20px;">{getTranslationByKey('CMC.PUBLISH')}</div>

                                </div>
                                <div className="photo_credits">© UNICEF/UNI595954/Cole</div>
                            </div>

                        </div>
                        <div className="donate_container" Style="margin-top: 10px;">
                            <div className="left_box_line_container" Style="margin: 0px;">
                                <div className="left_box_line_text">{getTranslationByKey('CMC.DONATE.MESSAGE')}</div>
                                <div className={left_box_line_button} Style="color: white !important;" onClick={() => window.open(user.modules.gsc.donate_link, "_blank")}>
                                    {getTranslationByKey('CMC.DONATE')}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* fourth column */}
                    <div className="column leaderboard_column">
                        <div Style="margin-bottom: 10px;">
                            <div className="team_container mobile_team_container">
                                <div id="info_popup_icon_team" onClick={() => handleModal(true, 'team_info_popup')} >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" class="bi bi-info-circle pointer" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                    </svg>
                                </div>
                                <div className="team_box">
                                    <div className="box_title_container">
                                        {/*<div className="box_title">{getTranslationByKey('CMC.RECENTLYJOINED')}</div>*/}
                                        <div className="box_title">{getTranslationByKey('CMC.TEAM')}</div>
                                    </div>

                                    <div className="filters ">
                                        {props.user && props.user.modules && props.user.modules.gsc && !props.user.modules.gsc.challenges[0].is_active ? '' : <div className={today_team ? "selected_button" : "unselected_button"} onClick={() => handleTeamButton('today')}>{getTranslationByKey('CMC.TODAY')}</div>}
                                        {props.user && props.user.modules && props.user.modules.gsc && !props.user.modules.gsc.challenges[0].is_active ? '' : <div className={week_team ? "selected_button" : "unselected_button"} onClick={() => handleTeamButton('week')}>{getTranslationByKey('CMC.THISWEEK')}</div>}
                                        <div className={total_team ? "selected_button" : "unselected_button"} onClick={() => handleTeamButton('month')}>{getTranslationByKey('CMC.ALL')}</div>
                                    </div>

                                    <div className="user_container recently_joined" Style={!joined ? "max-height:320px" : ''}>
                                        {team_loading ?
                                            <div className="loader-cont-recently"><div className="loader"> </div></div>
                                            :
                                            <>
                                                {team_leaderboard_list === '' ?
                                                    <div className="gucci_text text_center" Style="margin-top: 20px">{getTranslationByKey('CMC.NODATAAVAILABLE')}</div> :
                                                    team_leaderboard_list}
                                                {teamLeaderboard.length > 10 ?
                                                    <div className="text_center ">
                                                        <div className="see_button pointer" onClick={() => handleModal(true, 'see_all_team')}>{getTranslationByKey('CMC.SEEALL')}</div>
                                                    </div> : ''}
                                            </>}
                                        <div className="flex" Style={'position:absolute; bottom:10px; width:96%'}>
                                            {!joined || !user.modules.gsc.challenges[0].is_active ? '' :
                                                props.user && props.user.modules && props.user.modules.gsc && props.user.modules.gsc.challenges && props.user.modules.gsc.challenges[0] && props.user.modules.gsc.challenges[0].my_team ?
                                                    <div className='team_button' onClick={() => handleModal(true, 'my_team')}>{getTranslationByKey('CMC.MYTEAM')}</div>
                                                    : <div className='team_button' onClick={() => handleModal(true, 'create_team')}>{getTranslationByKey('CMC.CREATETEAM')}</div>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="leaderboard_container mobile" >
                            <div className="box_title_container">
                                <div className="box_title">{getTranslationByKey('CMC.LEADERBOARD')}</div>
                            </div>
                            <div className="flex filters_container">
                                <div className="filters " Style="margin-left: -5px;">
                                    {props.user && props.user.modules && props.user.modules.gsc && !props.user.modules.gsc.challenges[0].is_active ? '' : <div className={type === 'region' ? "selected_button" : "unselected_button"} onClick={() => setType('region')}>{getTranslationByKey('CMC.REGION')}</div>}
                                    {props.user && props.user.modules && props.user.modules.gsc && !props.user.modules.gsc.challenges[0].is_active ? '' : <div className={type === 'user' ? "selected_button" : "unselected_button"} onClick={() => setType('user')}>{getTranslationByKey('CMC.USER')}</div>}
                                </div>
                                <div className="filters filters_mobile" Style="margin-left: 35px; justify-content:right">
                                    {props.user && props.user.modules && props.user.modules.gsc && !props.user.modules.gsc.challenges[0].is_active ? '' : <div className={today ? "selected_button" : "unselected_button"} onClick={() => handleButton('today')}>{getTranslationByKey('CMC.TODAY')}</div>}
                                    {props.user && props.user.modules && props.user.modules.gsc && !props.user.modules.gsc.challenges[0].is_active ? '' : <div className={week ? "selected_button" : "unselected_button"} onClick={() => handleButton('week')}>{getTranslationByKey('CMC.THISWEEK')}</div>}
                                    <div className={total ? "selected_button" : "unselected_button"} onClick={() => handleButton('total')}>{getTranslationByKey('CMC.ALL')}</div>
                                </div>
                            </div>
                            {type === 'user' ?
                                (leaderboard && leaderboard.length > 0 ?
                                    <React.Fragment>
                                        {leaderboard_loading ? <div className="loader-cont"><div className="loader"> </div></div> : <><div className="leaderboard_users">
                                            {leaderboard_users}
                                            {leaderboard.length > 10 ?
                                                <div className="text_center">
                                                    <div className="see_button pointer" onClick={() => handleModal(true, 'see_all_leaderboard')}>{getTranslationByKey('CMC.SEEALL')}</div>
                                                </div>
                                                :
                                                null
                                            }

                                        </div>
                                        </>}
                                    </React.Fragment>

                                    :

                                    (leaderboard_loading ? <div className="loader-cont"><div className="loader"> </div></div> :
                                        <React.Fragment>
                                            <div className="waiting_steps_img"></div>
                                            <div className="gucci_text text_center">{getTranslationByKey('CMC.WAITINGFORFIRSTSTEPS')}</div>
                                        </React.Fragment>))
                                :
                                (region_leaderboard && region_leaderboard.length > 0
                                    ? <React.Fragment>
                                        {leaderboard_loading ?
                                            <div className="loader-cont"><div className="loader"> </div></div>
                                            : <div className="leaderboard_users">{region_leaderboard_list}
                                                {region_leaderboard.length > 10 ?
                                                    <div className="text_center">
                                                        <div className="see_button pointer" onClick={() => handleModal(true, 'see_all_region_leaderboard')}>{getTranslationByKey('CMC.SEEALL')}</div>
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>}
                                    </React.Fragment> : '')}

                        </div>
                    </div>
                </div >
        }

        {/* see all users modal*/}
        <Modal
            isOpen={seeAllLeaderboardModalStatus}
            onAfterOpen={null}
            onRequestClose={() => handleModal(false, 'see_all_leaderboard')}
            style={seeAllModalStyles}
            className={'modal_base modal_popup modal_popup'}
            contentLabel="Leaderboard"
            appElement={document.getElementById('root') || undefined}
        >
            <div className="modal_content_container">
                <div className="modal_header flex">
                    <div className="modal_content_title"> {getTranslationByKey('CMC.LEADERBOARD')}</div>
                    <div className="close_button" >
                        <svg onClick={() => handleModal(false, 'see_all_leaderboard')} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                    </div>
                </div>

                <input type='text' className={window.innerWidth > 450 && window.innerHeight > 800 ? "input_activity mt-3" : "input_activity"} onChange={(e) => handleText(e)} placeholder='Search' />

                <div >
                    <div className="leaderboard_see_all_cont" Style={window.innerHeight < 600 ? 'height:250px; margin-top:5px' : (window.innerHeight < 800 ? 'height:300px; margin-top:5px' : '')}>
                        {filter_loading ? <div className="loader-cont"><div className="loader"> </div></div> :
                            (leaderboard.length > 0 && !leaderboard_loading && !filter_loading ? leaderboard_users_modal : '')}
                    </div>
                </div>

                <Pagination style={{ marginTop: '10px', marginBottom: '10px', marginLeft: '1%' }} onChange={handleChangePage} page={currentPage}
                    count={search_text === '' ? ((parseInt(leaderboard.length / page_items) < (leaderboard.length / page_items) ? (parseInt(leaderboard.length / page_items) + 1) : parseInt(leaderboard.length / page_items)))
                        : ((parseInt(total_filtered_leaderboard.length / page_items) < (total_filtered_leaderboard.length / page_items) ? (parseInt(total_filtered_leaderboard.length / page_items) + 1) : parseInt(total_filtered_leaderboard.length / page_items)))} />
            </div>

        </Modal>
        {/* see all teams modal*/}
        <Modal
            isOpen={seeAllTeamModalStatus}
            onAfterOpen={null}
            onRequestClose={() => handleModal(false, 'see_all_team')}
            style={seeAllModalStyles}
            contentLabel="Team"
            className={'modal_base_team modal_popup modal_popup'}
            appElement={document.getElementById('root') || undefined}
        >
            <div className="modal_content_container">
                <div className="modal_header flex">
                    <div className="modal_content_title">Teams</div>
                    <div className="close_button" >
                        <svg onClick={() => handleModal(false, 'see_all_team')} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                    </div>
                </div>
                <input type='text' className={window.innerWidth > 450 && window.innerHeight > 600 ? "input_activity mt-3" : 'input_activity'} onChange={(e) => handleTextTeam(e)} placeholder='Search' />
                <div Style={window.innerWidth < 450 ? "height: 320px; overflow:auto; " : ''}>



                    <div className="leaderboard_see_all_cont mt-3" Style={window.innerHeight < 600 ? 'height:250px' : (window.innerHeight < 800 ? 'height:300px' : 'height:400px')}>
                        {filter_loading ? <div className="loader-cont"><div className="loader"> </div></div> :
                            (teamLeaderboard.length > 0 && !filter_loading ? team_users_modal : '')}
                    </div>
                </div>
                <Pagination style={{ marginTop: '10px', marginBottom: '10px', marginLeft: '1%' }} onChange={handleChangePageTeam} page={currentPage}
                    count={search_text === '' ? ((parseInt(teamLeaderboard.length / page_items) < (teamLeaderboard.length / page_items) ? (parseInt(teamLeaderboard.length / page_items) + 1) : parseInt(teamLeaderboard.length / page_items)))
                        : ((parseInt(filtered_teams.length / page_items) < (filtered_teams.length / page_items) ? (parseInt(filtered_teams.length / page_items) + 1) : parseInt(filtered_teams.length / page_items)))} />
            </div>

        </Modal>
        {/* my team modal*/}
        <Modal
            isOpen={myTeamModalStatus}
            onAfterOpen={null}
            onRequestClose={() => handleModal(false, 'my_team')}
            style={seeAllModalStyles}
            contentLabel="Team"
            className={'modal_base_my_team modal_popup'}
            appElement={document.getElementById('root') || undefined}
        >
            <div className="modal_content_container">
                <div className="modal_header flex">
                    <div className="modal_content_title">{my_team_info.name ? my_team_info.name : ''}</div>
                    <div className="close_button" >
                        <svg onClick={() => handleModal(false, 'my_team')} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                    </div>
                </div>
                <input type='text' className={window.innerWidth > 450 && window.innerHeight > 600 ? "input_activity mt-3" : "input_activity"} onChange={(e) => handleTextMyTeam(e)} placeholder='Search' />


                <div className="leaderboard_see_all_cont" Style={window.innerHeight < 800 ? 'height:200px' : 'height:400px'}>
                    {filter_loading ? <div className="loader-cont"><div className="loader"> </div></div> :
                        (my_team.length > 0 && !filter_loading ? my_team_users_modal : '')}
                </div>
                {search_text === '' && my_team.length > 10 || search_text !== '' && filtered_teams.length > 10 ? <Pagination style={{ marginTop: '10px', marginBottom: '60px', marginLeft: '1%' }} onChange={handleChangePageMyTeam} page={currentPage}
                    count={search_text === '' ? ((parseInt(my_team.length / page_items) < (my_team.length / page_items) ? (parseInt(my_team.length / page_items) + 1) : parseInt(my_team.length / page_items)))
                        : ((parseInt(filtered_teams.length / page_items) < (filtered_teams.length / page_items) ? (parseInt(filtered_teams.length / page_items) + 1) : parseInt(filtered_teams.length / page_items)))} />
                    : ''}

                {!filter_loading ? <div className="w-100">
                    {window.innerWidth > 1000 ?
                        <div className="confirm_add_activity_button" onClick={copy}>{getTranslationByKey('CMC.COPY')}</div> :


                        <RWebShare
                            data={{
                                text: "",
                                url: window.location.origin + '/team/' + my_team_info.id + '/',
                                title: "",
                            }}
                            onClick={() => console.log("shared successfully!")}
                        >
                            <button className="confirm_add_activity_button">{getTranslationByKey('CMC.SHARE')}</button>
                        </RWebShare>

                    }
                </div>
                    : ''}
            </div>

        </Modal >
        {/* add Activity modal */}
        < Modal
            isOpen={activityModalStatus}
            onAfterOpen={null}
            onRequestClose={() => handleModal(false, 'activity')
            }
            //style={customModalStyles}
            className={'activity_modal'}
            appElement={document.getElementById('root') || undefined}
        >
            <div className="modal_content_container">
                <div className="modal_header flex">
                    <div className="modal_content_title">{getTranslationByKey('CMC.ADDACTIVITY')}</div>
                    <div className="close_button" >
                        <svg onClick={() => handleModal(false, 'activity')} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                    </div>
                </div>
                <div className="w-100">
                    {user && user.modules && user.modules.gsc && user.modules.gsc.connected_to_strava ?
                        <div className="gucci_text text_center mt-4 w-100">
                            {getTranslationByKey('CMC.STRAVACONNECTIONMESSAGE')}</div>
                        :
                        <React.Fragment><div className="gucci_text text_center mt-4 w-100">{getTranslationByKey('CMC.AUTOMATICIMPORT.MESSAGE')} </div>
                            {window.location.hostname == 'changemakerschallenge.gucci.cn' ?
                                <div className="strava_button" onClick={() => window.open('https://www.strava.com/oauth/authorize?client_id=97095&response_type=code&redirect_uri=https://smartoffice-api.gucci.cn/api/strava/exchange_token/cn/' + user.id + '&approval_prompt=force&scope=activity:read_all', "_blank")}>{getTranslationByKey('CMC.CONNECTSTRAVA')} </div>
                                :
                                <div className="strava_button" onClick={() => window.open('https://www.strava.com/oauth/authorize?client_id=97095&response_type=code&redirect_uri=https://smartoffice.globalbit.gucci/api/strava/exchange_token/' + user.id + '&approval_prompt=force&scope=activity:read_all', "_blank")}>{getTranslationByKey('CMC.CONNECTSTRAVA')} </div>
                            }
                        </React.Fragment>}
                    <div className="">
                        {activity_loader ?
                            <div className="loader-cont"><div className="loader"> </div></div>
                            :
                            <div className="activity_form_container">
                                <div className="activity_label">{getTranslationByKey('CMC.ACTIVITYTYPE')}</div>
                                <Select
                                    value={selectedActivity}
                                    onChange={(e) => handleActivitySelect(e)}
                                    options={activity_options_new}
                                    className="select_activity"
                                    placeholder={selectedActivityLabel}
                                    styles={customStylesSelect}
                                />
                                <div className="add_activity_measurement_container">
                                    <div>
                                        <div className="activity_label">{getTranslationByKey('CMC.MEASUREMENTUNIT')}</div>
                                        <Select
                                            value={measurementSelectValue}
                                            onChange={(e) => handleMeasurementSelectValue(e)}
                                            options={measurement_option_new}
                                            className="select_activity"
                                            placeholder={measurementSelectLabel}
                                            styles={customStylesMeasurementSelect}
                                        />
                                    </div>
                                    <div>
                                        <div className="activity_label">{getTranslationByKey('CMC.MEASUREMENTVALUE')}</div>
                                        <input value={measurementValue} type='text' className="input_activity datepicker_modal" onChange={(e) => setMeasurementValue(e.target.value)} />
                                        {measurementValue && regex.test(measurementValue) || regex2.test(measurementValue) ? '' :
                                            <div className="gucci_text red">* Please insert number value (0.0) </div>}
                                    </div>
                                </div>

                                <div className="activity_label">{getTranslationByKey('CMC.DATE')}</div>
                                <div className="activity_date_input">
                                    <DatePicker
                                        selected={activity_date}
                                        onChange={(date) => setActivityDate(date)}
                                        onSelect={(date) => setActivityDate(date)}
                                        placeholderText="gg/mm/aaaa"
                                        minDate={new Date(challenge.from)}
                                        maxDate={new Date()}
                                        dateFormat="dd/MM/yyyy"
                                        className="date_picker_activity datepicker_modal"

                                    />
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-calendar activity_date_icon" viewBox="0 0 16 16">
                                        <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                                    </svg>
                                </div>
                                <div className="w-100">
                                    <div className="confirm_add_activity_button" onClick={() => addActivity()}>{getTranslationByKey('CMC.ADD')}</div>
                                    <div className="cancel_button" onClick={() => handleModal(false, 'activity')}>{getTranslationByKey('CMC.CANCEL')}</div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </Modal >
        {/* my tracking modal*/}
        < Modal
            isOpen={trackingModalStatus}
            onAfterOpen={null}
            onRequestClose={() => handleModal(false, 'tracking')}
            className={'modal_base_my_tracking modal_popup'}
            //style={customModalStylesTracking}
            appElement={document.getElementById('root') || undefined}
        >
            <div className="modal_content_container">
                <div className="modal_header flex">
                    <div className="modal_content_title">{getTranslationByKey('CMC.MYTRACKING')}</div>
                    <div className="close_button" >
                        <svg onClick={() => handleModal(false, 'tracking')} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                    </div>
                </div>
                <div className="modal_content_subtitle">
                    {getTranslationByKey('CMC.ACTIVITYHISTORY')}
                </div>
                <div className="modal_activity_list_container">
                    <div className="modal_activity_list">
                        {tracking_loader ?
                            <div className="loader-cont"><div className="loader"> </div></div>
                            :
                            (my_trackings.length > 0 ?
                                my_trackings.map((activity) =>
                                    <div className="activity_line flex">
                                        {activity.image_url ?
                                            <div className="activity_icon_image_present" Style={"background-image: url('" + activity.image_url + "');"}></div>
                                            :
                                            <div className="activity_icon_image">{activity.name[0]}</div>}
                                        <div className="activity_list_text_container">
                                            <div className="activity_list_text bold flex">{activity.name} | {activity.value} {activity.unit.unit} | {activity.coins} {getTranslationByKey('CMC.COINS')}</div>
                                            <div className="activity_list_text" Style="font-size: 14px">{formatData(activity.date)}</div>
                                        </div>
                                        {props.user && props.user.modules && props.user.modules.gsc && !props.user.modules.gsc.challenges[0].is_active ? '' : (
                                            activity.can_delete ?
                                                <div>
                                                    <svg onClick={() => handleModal(true, 'delete', activity)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-trash delete_activity pointer" viewBox="0 0 16 16">
                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                        <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                    </svg>
                                                </div>
                                                :
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="grey" class="bi bi-trash delete_activity" viewBox="0 0 16 16">
                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                        <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                    </svg>
                                                </div>
                                        )}
                                    </div>
                                )
                                :
                                <div className="gucci_text text_center" Style="margin-top: 20px">{getTranslationByKey('CMC.NODATAAVAILABLE')}</div>
                            )}
                    </div>
                </div>
            </div>
        </Modal >
        {/* create team modal*/}
        < Modal
            isOpen={createTeamStatus}
            onAfterOpen={null}
            onRequestClose={() => handleModal(false, 'create_team')}
            className={'modal_base_create modal_popup'}
            //style={customModalStylesTracking}
            appElement={document.getElementById('root') || undefined}
        >
            {team_loading ? <div className="loader-cont">< div className="loader" > </div ></div > :
                <div className="modal_content_container" Style={'padding-bottom: 70px;'}>
                    <div className="modal_header flex">
                        <div className="modal_content_title" Style={'font-size:20px'}>{!team_created ? getTranslationByKey('CMC.CREATETEAM') : getTranslationByKey('CMC.TEAM') + ' "' + team_info.name + '" ' + 'created!'} </div>
                        <div className="close_button" >
                            <svg onClick={() => handleModal(false, 'create_team')} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                        </div>
                    </div>
                    <div Style={window.innerWidth < 450 ? 'overflow: auto; padding-right:8px;   height: 330px;    margin: 10px 0px;' : ''}>

                        <div className="modal_content_subtitle flex" Style={!team_created ? 'font-size:18px' : 'margin-top:5%'}>
                            {!team_created ? <>
                                {getTranslationByKey('CMC.TEAMNAME')} :
                                <input Style={window.innerWidth < 500 ? 'margin-top:-5px; border: none;border-bottom: 1px solid black;width: 135px;font-family: "GucciSans-Book"' : 'border: none;border-bottom: 1px solid black;width: 250px;font-family: "GucciSans-Book"'} className="ml-3" type="text" onChange={(e) => setTeamName(e.target.value)} value={team_name} />
                            </>
                                : ''}
                        </div>

                        <div className="modal_content_title bold mt-4" >{getTranslationByKey('CMC.TEAM.CREATE.TITLE')}</div>
                        <ul className="modal_content_subtitle_text">
                            <li dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(getTranslationByKey('CMC.TEAM.CREATE.TEXT1')) }}></li>
                            <li dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(getTranslationByKey('CMC.TEAM.CREATE.TEXT2')) }}></li>
                        </ul>
                    </div>
                    {!team_created ? <div className="w-100" Style={'display: inline-flex;    place-content: center;    position: absolute;    bottom: 40px; left:0'}>
                        <div className="cancel_create_team_button" onClick={() => handleModal(false, 'create_team')}>{getTranslationByKey('CMC.CANCEL')}</div>
                        <div className="confirm_create_team_button" onClick={createTeam}>{getTranslationByKey('CMC.CREATE')}</div>
                    </div> :
                        window.innerWidth > 1000 ?
                            <div className="confirm_add_activity_button" onClick={copyTeamLink}>{getTranslationByKey('CMC.COPY')}</div> :
                            <RWebShare
                                data={{
                                    text: "",
                                    url: window.location.href,
                                    title: "",
                                }}
                            >   <div className="confirm_add_activity_button">{getTranslationByKey('CMC.SHARE')}</div>
                            </RWebShare>}

                </div>}
        </Modal >
        {/*  activity info modal*/}
        < Modal
            isOpen={infoPopup}
            onAfterOpen={null}
            onRequestClose={() => setInfoPopup(false)}
            style={customModalStylesTracking}
            className={'modal_base modal_popup'}
            appElement={document.getElementById('root') || undefined}
        >
            <div className="modal_content_container">
                <div className="modal_header flex">
                    <div className="modal_content_title"></div>
                    <div className="close_button" >
                        <svg onClick={() => setInfoPopup(false)} xmlns="http://www.w3.org/2000/svg" width="27" height="27" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                    </div>
                </div>
                <div className="info_popup_container">
                    <table className="info_popup_table">
                        <tr>
                            <th className="info_popup_table_header" colSpan={2}>{getTranslationByKey('CMC.ACTIVITYTYPE')}</th>
                            <th className="info_popup_table_header">{getTranslationByKey('CMC.MEASUREMENTUNIT')}</th>
                            <th className="info_popup_table_header">{getTranslationByKey('CMC.COINS')}</th>
                        </tr>
                        {conversion_info.length > 0 ?
                            conversion_info.map((elem) =>
                                <tr className="info_popup_table_row">
                                    <td colSpan={2} className="info_popup_table_cell">
                                        <div className="info_popup_table_cell_image">
                                            <div className="activity_image_container" style={{ backgroundImage: 'url(' + elem.image_url + ')' }}></div>
                                            {elem.name}
                                        </div>
                                    </td>
                                    <td className="info_popup_table_cell">{elem.conversion} {elem.unit}</td>
                                    <td className="info_popup_table_cell">{elem.coins}</td>
                                </tr>
                            )
                            : null}

                    </table>
                </div>

            </div>
        </Modal >
        {/*team info modal*/}
        < Modal
            isOpen={teamModalStatus}
            onAfterOpen={null}
            onRequestClose={() => handleModal(false, 'join_team')}
            style={seeAllModalStyles}
            contentLabel="Team"
            className={'modal_base_info_team modal_popup'}
            appElement={document.getElementById('root') || undefined}
        >
            <div className="modal_content_container">
                <div className="modal_header flex">
                    <div className="modal_content_title">
                        {props.user && props.user.modules && props.user.modules.gsc && props.user.modules.gsc.challenges && props.user.modules.gsc.challenges[0] && props.user.modules.gsc.challenges[0].my_team || !joined || !user.modules.gsc.challenges[0].is_active ? '' : 'Join '}{team_info.name ? team_info.name : ''}</div>
                    <div className="close_button" >
                        <svg onClick={() => handleModal(false, 'join_team')} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                    </div>
                </div>

                {joined && props.user && props.user.modules && props.user.modules.gsc && props.user.modules.gsc.challenges && props.user.modules.gsc.challenges[0] && !props.user.modules.gsc.challenges[0].my_team ?
                    <div className="modal_content_subtitle_text" Style={window.innerWidth < 450 ? 'margin-top:3px' : ''} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(getTranslationByKey('CMC.TEAM.CREATE.TEXT2')) }}>
                    </div> : (!joined ? <div className="modal_content_subtitle_text" Style={window.innerWidth < 450 ? 'margin-top:3px' : ''} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(getTranslationByKey('CMC.TEAM.JOINCHALLENGEBEFORE')) }}>
                    </div> : '')}
                <input type='text' className={window.innerWidth > 450 && window.innerHeight > 600 ? "input_search_team_user mt-3" : "input_search_team_user"} onChange={(e) => handleTextSingleTeam(e)} placeholder='Search' />


                <div className="leaderboard_see_all_team_cont"
                    Style={!joined ? window.innerWidth < 450 && window.innerHeight < 600 ? 'height:240px' :
                        (!joined && window.innerHeight < 600 ? 'height:190px' :
                            (!joined && window.innerHeight < 700 ? 'height:220px' :
                                (!joined && window.innerHeight < 800 ? 'height:280px' :
                                    (!joined && window.innerHeight < 1000 ? 'height:340px' :
                                        'height:450px')))) :
                        (props.user && props.user.modules && props.user.modules.gsc && props.user.modules.gsc.challenges && props.user.modules.gsc.challenges[0] && !props.user.modules.gsc.challenges[0].my_team && window.innerHeight < 600 ? 'height:120px' :
                            (props.user && props.user.modules && props.user.modules.gsc && props.user.modules.gsc.challenges && props.user.modules.gsc.challenges[0] && !props.user.modules.gsc.challenges[0].my_team && window.innerHeight < 800 ? 'height:180px' :
                                (props.user && props.user.modules && props.user.modules.gsc && props.user.modules.gsc.challenges && props.user.modules.gsc.challenges[0] && !props.user.modules.gsc.challenges[0].my_team && window.innerHeight > 800 ? 'height:300px' :
                                    (window.innerWidth < 450 && props.user && props.user.modules && props.user.modules.gsc && props.user.modules.gsc.challenges && props.user.modules.gsc.challenges[0] && !props.user.modules.gsc.challenges[0].my_team ? 'height:250px' :
                                        (window.innerHeight < 600 ? 'height:220px' :
                                            (window.innerHeight < 800 ? 'height:280px' : 'height:380px'))))))}>
                    {filter_loading ? <div className="loader-cont"><div className="loader"> </div></div> :
                        (selected_modal_team.length > 0 && !filter_loading ? selected_team_users_modal : '')}
                </div>
                {search_text === '' && selected_total_modal_team.length > 10 || search_text !== '' && filtered_selected_team_users.length > 10 ? <Pagination style={{ marginTop: window.innerWidth < 450 ? '4px' : '10px', marginBottom: '10px', marginLeft: '1%' }} onChange={handleChangePageSingleTeam} page={currentPage}
                    count={search_text === '' ? ((parseInt(selected_total_modal_team.length / page_items) < (selected_total_modal_team.length / page_items) ? (parseInt(selected_total_modal_team.length / page_items) + 1) : parseInt(selected_total_modal_team.length / page_items)))
                        : ((parseInt(filtered_selected_team_users.length / page_items) < (filtered_selected_team_users.length / page_items) ? (parseInt(filtered_selected_team_users.length / page_items) + 1) : parseInt(filtered_selected_team_users.length / page_items)))} />
                    : ''}
                <div className="w-100">
                    {props.user && props.user.modules && props.user.modules.gsc && props.user.modules.gsc.challenges && props.user.modules.gsc.challenges[0] && props.user.modules.gsc.challenges[0].my_team && props.user.modules.gsc.challenges[0].my_team.id == team_info.id ?
                        !filter_loading ? <div className="w-100">
                            {window.innerWidth > 1000 ?
                                <div className="confirm_add_activity_button" onClick={copyTeamLink}>{getTranslationByKey('CMC.COPY')}</div> :


                                <RWebShare
                                    data={{
                                        text: "",
                                        url: window.location.origin + '/team/' + team_info.id + '/',
                                        title: "",
                                    }}
                                    onClick={() => console.log("shared successfully!")}
                                >
                                    <button className="confirm_add_activity_button">{getTranslationByKey('CMC.SHARE')}</button>
                                </RWebShare>

                            }
                        </div>
                            : ''
                        : (props.user && props.user.modules && props.user.modules.gsc && props.user.modules.gsc.challenges && props.user.modules.gsc.challenges[0] && props.user.modules.gsc.challenges[0].my_team || !joined || !user.modules.gsc.challenges[0].is_active ? '' :
                            <div className="confirm_add_activity_button" onClick={() => joinToTeam(team_info)}>{getTranslationByKey('CMC.JOIN')}</div>)}

                </div>
            </div>

        </Modal >
        {/*team generic info modal popup */}
        < Modal
            isOpen={modalTeamInfoPopuo}
            onAfterOpen={null}
            onRequestClose={() => handleModal(false, 'team_info_popup')
            }
            style={seeAllModalStyles}
            contentLabel="Team"
            className={'modal_base_team_info modal_popup'}
            appElement={document.getElementById('root') || undefined}
        >
            <div className="modal_content_container" >
                <div className="modal_header flex">
                    <div className="modal_content_title">
                        Team Info </div>
                    <div className="close_button" >
                        <svg onClick={() => handleModal(false, 'team_info_popup')} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                    </div>
                </div>
                <div Style={window.innerWidth > 450 && window.innerHeight > 600 ? 'height:60vh;margin-top: 10px; overflow:auto; padding-right: 2px;' : "height: 300px;margin-top: 10px; overflow:auto; padding-right: 2px;"}>
                    <div className="modal_content_subtitle_text_info" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(getTranslationByKey('CMC.TEAM.INFO.TEXT1')) }}></div>
                    <ul>
                        <li className="modal_content_subtitle_text_info" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(getTranslationByKey('CMC.TEAM.INFO.TEXT2')) }}></li>
                        <li className="modal_content_subtitle_text_info" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(getTranslationByKey('CMC.TEAM.INFO.TEXT3')) }}></li>
                        <li className="modal_content_subtitle_text_info" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(getTranslationByKey('CMC.TEAM.INFO.TEXT4')) }}></li>
                    </ul>
                    <div className="modal_content_subtitle_text_info" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(getTranslationByKey('CMC.TEAM.INFO.TEXT5')) }}></div>
                    <div className="modal_content_subtitle_text_info" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(getTranslationByKey('CMC.TEAM.INFO.TEXT6')) }}></div>

                </div>
            </div>

        </Modal >
        <PhotoPopup publishPopup={publishPopup} setPublishPopup={setPublishPopup} getTranslationByKey={getTranslationByKey} ></PhotoPopup>
        {/* delete tracking modal*/}
        <Modal
            isOpen={modal_delete_tracking}
            onAfterOpen={null}
            onRequestClose={() => handleModal(false, 'delete', '')}
            style={customModalStylesTracking}
            className={'modal_base_delete confirm_delete modal_popup'}
            appElement={document.getElementById('root') || undefined}
        >
            <div className="modal_content_container">
                <div className="modal_header flex">
                    <div className="modal_content_title">Remove activity</div>
                    <div className="close_button" >
                        <svg onClick={() => handleModal(false, 'delete', '')} xmlns="http://www.w3.org/2000/svg" width="27" height="27" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                    </div>
                </div>
                <div className="info_popup_container">
                    Do you want to delete your activity?

                    <div className="mt-10">
                        <div className="bold">Activity: <span className="light"> {selectedTracking.name} </span></div>
                        <div className="bold">Value:  <span className="light">{selectedTracking.value}</span></div>
                        <div className="bold">Coins:  <span className="light">{selectedTracking.coins}</span></div>
                        <div className="bold">Date:  <span className="light"> {formatData(selectedTracking.date)}</span></div>
                    </div>
                    <div className="w-100 flex" Style="margin-top: 40px">
                        <div className="cancel_delete_button" onClick={() => handleModal(false, 'delete', '')}>{getTranslationByKey('CMC.CANCEL')}</div>
                        <div className="confirm_delete_activity_button" onClick={() => deleteActivity()}>Delete</div>
                    </div>
                </div>

            </div >
        </Modal >

    </div >


    )
}